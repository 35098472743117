<template>
  <div class="users-update page-layout">
    <PageHeader
      :title="
        user.id === self.id
          ? 'Vos informations'
          : `Informations de l'utilisateur`
      "
      :has-back="true"
      :has-actions="user.id !== self.id"
      @back="goBack"
    >
      <md-button
        v-if="user.id !== self.id"
        class="md-primary"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="openConfirmTrashModal"
      >
        Supprimer cet utilisateur
      </md-button>
    </PageHeader>

    <LoadingCard v-if="isLoading" />

    <!-- ## Les informations de l'utilisateur ## -->
    <md-card
      v-else
      class="users-update__card md-layout md-layout-item md-size-100 md-small-size-100"
    >
      <md-card-content>
        <!-- Prénom -->
        <div class="md-layout md-alignment-center-space-between">
          <div class="meep-input md-layout-item md-size-45">
            <md-field :class="{ 'md-invalid': errors.has('firstname') }">
              <label>Prénom *</label>

              <md-input
                v-model="newInfos.firstname"
                v-validate="'required|alpha_spaces'"
                name="firstname"
                type="text"
              />

              <span v-show="errors.has('firstname')" class="md-error">
                {{ errors.first("firstname") }}
              </span>
            </md-field>
          </div>

          <!-- Nom -->
          <div class="meep-input md-layout-item md-size-45">
            <md-field :class="{ 'md-invalid': errors.has('lastname') }">
              <label>Nom *</label>

              <md-input
                v-model="newInfos.lastname"
                v-validate="'required|alpha_spaces'"
                name="lastname"
                type="text"
              />

              <span v-show="errors.has('lastname')" class="md-error">
                {{ errors.first("lastname") }}
              </span>
            </md-field>
          </div>
        </div>

        <!-- Email -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('email') }">
            <label>E-mail *</label>

            <md-input
              v-model="newInfos.email"
              v-validate="'required|email'"
              disabled
              name="email"
              type="email"
            />
            <span class="md-helper-text">{{ $t("form.emailDisabled") }}</span>
          </md-field>
        </div>

        <!-- Téléphone fixe -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
            <label>Téléphone fixe</label>

            <md-input
              v-model="newInfos.telfixe"
              v-validate="'telephone'"
              name="telfixe"
              type="tel"
            />

            <span v-show="errors.has('telfixe')" class="md-error">
              {{ errors.first("telfixe") }}
            </span>
          </md-field>
        </div>

        <!-- Téléphone portable  -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('telportable') }">
            <label>Téléphone portable</label>

            <md-input
              v-model="newInfos.telportable"
              v-validate="'telephone'"
              name="telportable"
              type="tel"
            />

            <span v-show="errors.has('telportable')" class="md-error">
              {{ errors.first("telportable") }}
            </span>
          </md-field>
        </div>

        <!-- Numéro et voie -->
        <div class="meep-input">
          <md-field>
            <label>Adresse</label>

            <md-input v-model="newInfos.adresse" name="adresse" type="text" />
          </md-field>
        </div>

        <div class="md-layout md-alignment-center-space-between">
          <!-- Ville -->
          <div class="meep-input md-layout-item md-size-45">
            <md-field :class="{ 'md-invalid': errors.has('ville') }">
              <label>Ville</label>

              <md-input
                v-model="newInfos.ville"
                v-validate="'alpha_spaces'"
                name="ville"
                type="ville"
              />
              <span v-show="errors.has('ville')" class="md-error">
                {{ errors.first("ville") }}
              </span>
            </md-field>
          </div>

          <!-- Code postal -->
          <div class="meep-input md-layout-item md-size-45">
            <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
              <label>Code postal</label>

              <md-input
                v-model="newInfos.codepostal"
                v-validate="'numeric|min:5|max:5'"
                name="codepostal"
                type="text"
              />

              <span v-show="errors.has('codepostal')" class="md-error">
                {{ errors.first("codepostal") }}
              </span>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-alignment-center-right">
          <!-- Le bouton sauvegarder -->
          <md-button class="md-raised md-primary" @click.native="onInfosSave">
            Sauvegarder
          </md-button>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :text="confirmGlobalCancelModalText"
      :object-to-act-upon="{}"
      @close="closeConfirmGlobalCancelModal"
      @confirm="goBack"
    />

    <confirm-action-modal
      v-if="isConfirmTrashModalOpen"
      :text="confirmTrashModalText"
      :object-to-act-upon="user"
      @close="closeConfirmTrashModal"
      @confirm="onTrash"
    />
  </div>
</template>

<script>
/* Modèles */
import usersModel from "../../model/users";

/* Composants */
import confirmActionModal from "../../components/modal/confirm-action";
import LoadingCard from "@/components/LoadingCard";

import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "UpdateUser",

  components: {
    PageHeader,
    LoadingCard,
    "confirm-action-modal": confirmActionModal,
  },

  data() {
    return {
      isLoading: false,
      canShowToastedMessages: false,
      user: {},

      /* Tampons pour éditer les informations */
      newInfos: {},
      isConfirmGlobalCancelModalOpen: false,
      isConfirmTrashModalOpen: false,

      confirmGlobalCancelModalText: {
        header: "Des modifications n'ont pas étés sauvegardées",
        body() {
          return "";
        },
        question: "Êtes-vous sûr de vouloir quitter cette page ?",
      },
      confirmTrashModalText: {
        header: "Suppression d'un utilisateur",
        body(user) {
          return (
            "Vous êtes sur le point de supprimer l'utilisateur <b>" +
            user.firstname +
            " " +
            user.lastname +
            "</b>"
          );
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },

  computed: {
    ...mapGetters(["self", "isJEP", "isJEPA"]),
  },

  watch: {
    $route: "loadData",
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        this.user = await usersModel.get(this.$route.params.id);

        this.onInfosCancel();
        this.canShowToastedMessages = true;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },

    goBack() {
      window.history.back();
    },

    async onInfosSave() {
      const result = await this.$validator.validateAll();
      if (!result) {
        this.$toasted.global.AppInfo({
          message: "Vous devez remplir les champs manquants",
        });

        return;
      }
      try {
        this.isLoading = true;
        await usersModel.update(this.newInfos);

        this.user.firstname = this.newInfos.firstname;
        this.user.lastname = this.newInfos.lastname;
        this.user.telfixe = this.newInfos.telfixe;
        this.user.telportable = this.newInfos.telportable;
        this.user.adresse = this.newInfos.adresse;
        this.user.codepostal = this.newInfos.codepostal;
        this.user.ville = this.newInfos.ville;
        this.user.email = this.newInfos.email;

        this.$toasted.global.AppSucces({
          message:
            this.user.id === this.self.id
              ? "Vos informations ont bien été mises à jour"
              : "Les informations de l'utilisateur ont bien été mises à jour",
        });

        this.$router.push("/dashboard/users/view/" + this.user.id);
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },
    onInfosCancel() {
      this.newInfos = {
        id: this.user.id,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        telfixe: this.user.telfixe,
        telportable: this.user.telportable,
        adresse: this.user.adresse,
        codepostal: this.user.codepostal,
        ville: this.user.ville,
        email: this.user.email,
      };

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onTrash() {
      this.isLoading = true;
      usersModel
        .putInTrash(this.user.id)
        .then(() => {
          this.isLoading = false;
          this.$router.push("/dashboard/users/");
          this.$toasted.global.AppSucces({
            message:
              "L'utilisateur '" +
              this.user.firstname +
              " " +
              this.user.lastname +
              "' a bien été supprimé",
          });
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    openConfirmTrashModal() {
      this.isConfirmTrashModalOpen = true;
    },
    closeConfirmTrashModal() {
      this.isConfirmTrashModalOpen = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.users-update__card {
  padding: 0 25%;

  @media (max-width: 600px) {
    padding: 0;
  }
}
</style>
